import {
  CHECKOUT_ORDER_FAILURE,
  CHECKOUT_ORDER_SUCCESS,
  GET_ALL_ORDER_SUCCESS,
  GET_ORDER_DETAILS_SUCCESS,
  PLACE_ORDER_SUCCESS
} from "../constants/actionTypes";

const INITIAL_STATE = {
  orderData: {
    customer: {},
    order: {},
    settings: {},
  },
  list: [],
  error: null
}

export default function orders(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHECKOUT_ORDER_SUCCESS:
      return { ...state, tempOrder: action.payload.data };
    case CHECKOUT_ORDER_FAILURE:
      return { ...state, error: action.payload.error };
    case PLACE_ORDER_SUCCESS:
      return { ...state, orderData: action.payload.data };
    case GET_ALL_ORDER_SUCCESS:
      return { ...state, list: action.payload.data.filter(order => order.order_status === 'Success') }
    case GET_ORDER_DETAILS_SUCCESS:
      return { ...state, currOrder: action.payload.data }
    default:
      return state;
  }
}
