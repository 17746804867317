
import {
  GET_CONTENTS_SUCCESS,
  GET_CONTENTS_FAILURE,
} from '../constants/actionTypes';

const INITIAL_STATE = {
  data: [],
  error: null
};

export default function contents(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CONTENTS_SUCCESS:
      return { ...state, data: action.payload.data };
    case GET_CONTENTS_FAILURE:
    default:
      return state;
  }
}
