import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    console.log(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <div className='container'><h2 style={{ color: '#a94442', padding: '180px 0px 80px' }}>We are sorry something went wrong.</h2></div>;
    }
    return children;
  }
}
