import {
    USER_LOGGED_OUT,
    UPDATE_CART,
    CLEAR_CART,
    LOAD_CART_DATA
} from '../constants/actionTypes';
import { cartData_local_storage_key } from "../helpers/common";

const defaultState = {
    data: [],
};

export default function cart(state = defaultState, action) {
    switch (action.type) {
        case UPDATE_CART:
            localStorage.setItem(cartData_local_storage_key, JSON.stringify(action.payload));
            return { ...state, data: action.payload };
        case LOAD_CART_DATA:
            let data = localStorage.getItem(cartData_local_storage_key) || '[]';
            try {
                return { ...state, data: JSON.parse(data) };
            } catch (e) {
                return { ...state, data: [] };
            }
        case USER_LOGGED_OUT:
        case CLEAR_CART:
            localStorage.removeItem(cartData_local_storage_key);
            return { ...state, data: [] };
        default:
            return state;
    }
}
