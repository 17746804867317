import { createAction } from 'redux-actions';
import { fetchAssets } from '../api/assets';
import { GET_ASSETS_FAILURE, GET_ASSETS_SUCCESS } from '../constants/actionTypes';

const getAssetsSuccess = createAction(GET_ASSETS_SUCCESS);
const getAssetsFailure = createAction(GET_ASSETS_FAILURE);

export function getAssets() {
  return dispatch => fetchAssets()
    .then(json => dispatch(getAssetsSuccess(json)))
    .catch(err => dispatch(getAssetsFailure(err)));
}
